import { CrmFilterField, CrmFilterInputType } from 'common-module/filter';

export const defaultSearchFilter: CrmFilterField = {
  type: CrmFilterInputType.search,
  dataIndex: 'search',
  placeholder: 'generic.search',
  span: { sm: 6 },
};

export const defaultSearchFilterWithOptions = (
  filter: Partial<CrmFilterField>
): CrmFilterField => {
  return {
    ...defaultSearchFilter,
    ...filter,
  };
};

export const getYesNoFilter = (
  filter: Partial<CrmFilterField>
): CrmFilterField => {
  return {
    dataIndex: '',
    span: { sm: 4 },
    ...filter,
    type: CrmFilterInputType.select,
    options: [
      { value: true, label: 'generic.yes' },
      { value: false, label: 'generic.no' },
    ],
  };
};
